export const buildFlatContentList = (contentByParent, id = 'root', depth = 0, list = [], parent) => {

  contentByParent[id]?.forEach((content, index) => {

    const item = {
      id: content.id,
      parentId: parent ? parent.id : 'root',
      depth,
      content
    };

    list.push(item);

    if(content.id !== id && contentByParent[content.id]) {
      buildFlatContentList(contentByParent, content.id, depth + 1, list, item);
    }

  });

  return list;

}

export const buildListOfContainedItems = (flattenedItems, parentSetId) => {

  const contained = [];

  const containedItems = flattenedItems.filter(item => item.parentId === parentSetId);
  containedItems.forEach(item => {
    if(item.content.page_type === "page"){
      contained.push(item.id)
    } else if(item.content.page_type === "set"){
      contained.push(item.id)
      const nestedItems = buildListOfContainedItems(flattenedItems, item.id);
      contained.push(nestedItems);
    }
  })

  return contained.flat();

}

export const shouldRenderItem = (flattenedItems, item, closedSets) => {
  // If the item's parent is root, always return true
  if(item.parentId === "root") return true;
  // If the item's parent isn't on the open sets list, return false
  else if(closedSets.includes(item.parentId)) return false;
  else {
    // If the item's parent is open, check the parent's parent recursively untill we reach the root
    const parent = flattenedItems.find(({id}) => id === item.parentId);
    return parent.parentId === "root" && !closedSets.includes(parent.id) 
      ? true 
      : shouldRenderItem(flattenedItems, parent, closedSets);
  }
}

export const isLastItemInSet = (flattenedItems, item) => {
  
  const containedItems = flattenedItems.filter(({parentId}) => parentId === item.parentId);
  const itemIndex = containedItems.findIndex(({id}) => id === item.id)
  return itemIndex === containedItems.length -1;

}


export const isItemContainedInDraggedSet = (flattenedItems, item, activeId) => {

  if(!activeId) return false;
  else if(item.parentId === "root") return false;
  else if(item.parentId === activeId) return true;
  else {
    const parentItem = flattenedItems.find(({id}) => id === item.parentId);
    return isItemContainedInDraggedSet(flattenedItems, parentItem, activeId);
  }

}